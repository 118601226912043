import React from 'react'

const FooterCopywrite = () => (
  <div className="copyright">
    <div className="container">
      <p>© 2020 Decoupled Days. All rights reserved.</p>
    </div>
  </div>
)

export default FooterCopywrite
